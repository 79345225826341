<template>
  <div class="about-me">
    <h1>About the Artist</h1>
    <p>Salena Metreger is a dynamic, versatile voice-over artist known for bringing scripts to life with professionalism, creativity, and unmatched energy. With a robust background in marketing and business, Salena crafts each project as a strategic narrative, helping brands connect authentically with their audiences. From warm, inviting tones to young, conversational deliveries to authoritative and knowledgeable, she transitions effortlessly between styles, tailoring each performance to meet client needs across various formats.
    <br><br>
    Her portfolio spans a wide range of industries and projects. She’s the voice behind captivating characters for webtoons with Toonorator Studios and an ongoing commercial spokesperson for Black Horizon Brewing. She’s known for her work as the editor and producer of The Great Voice Podcast, as well as a guest. She’s also the voice behind several podcast guest introduction reels and product training videos.
    <br><br>
    Whether it’s for commercials, e-learning, audiobooks, animation or video games, Salena’s voice adds depth and dimension, making her a powerful choice for projects aiming to resonate and engage.</p>
  </div>
  
  <!-- Equipment -->
  <div class="equipment">
    <h1>Equipment</h1>
    <ul>
      <li>Sound Treated In-Home Studio</li>
      <li>Audio-Technica AT2020 Cardioid Condenser Studio XLR Microphone with Pop Filter</li>
      <li>Beyerdynamic DT 770 PRO Headphones</li>
      <li>Focusrite Scarlett Solo 3rd Gen USB Audio Interface</li>
      <li>Reaper</li>
      <li>Thinkpad Custom Built Laptop</li>
    </ul>
  </div>
</template>

<style lang="scss">
  .equipment {
    li {
      margin-left: 20px;
      padding-left: 10px;
      font-size: 18px;
    }
  }
</style>